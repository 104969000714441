import * as JsNav from '@petitsys/js-nav';
import * as Player from './player';
import Scroller, * as Scroll from './scroll';
import * as Resize from './resize';
import * as MediaQueriesEvents from '@petitsys/media-queries-events';
import * as Messages from './messages';

const CARD_CLASS = 'Card';
const SCROLL_BUTTON_CLASS = 'ScrollButton';
const SCROLL_WRAPPER_CLASS = 'ScrollWrapper';
const HAS_SCROLL_WRAPPER = Symbol();

/**
 * @type {ServiceWorker}
 */
let WORKER = null;

/**
 * @type {Scroller}
 */
let CARD_SCROLLER = null;

/**
 * @type {Element}
 */
let MAIN = null;

/**
 * @type {import('./scroll').ScrollWrapperCreatedCallback}
 */
function moveMainContentToScrollWrapper(scrollable, wrapper) {
    for(let card of Array.from(scrollable.getElementsByClassName(CARD_CLASS))) {
        wrapper.appendChild(card);
    }
    scrollable.classList.add('HasScrollWrapper');
    scrollable[HAS_SCROLL_WRAPPER] = true;
}

/**
 * @type {import('./scroll').ScrollWrapperRemovedCallback}
 */
function moveScrollWrapperContentToMain(wrapper, scrollable) {
    for(let card of Array.from(wrapper.getElementsByClassName(CARD_CLASS))) {
        scrollable.appendChild(card);
    }
    scrollable.classList.remove('HasScrollWrapper');
    scrollable[HAS_SCROLL_WRAPPER] = false;
}

/**
 * @param {Element} scrollable
 * @param {import('./scroll').ScrollButtonState} left
 * @param {import('./scroll').ScrollButtonState} right
 */
function updateScrollButtons(scrollable, left, right) {
    if (left === Scroll.ScrollButtonState.ACTIVATE) scrollable.getElementsByClassName(`${SCROLL_BUTTON_CLASS} Left`).item(0).classList.add('Active');
    if (left === Scroll.ScrollButtonState.DEACTIVATE) scrollable.getElementsByClassName(`${SCROLL_BUTTON_CLASS} Left`).item(0).classList.remove('Active');
    if (right === Scroll.ScrollButtonState.ACTIVATE) scrollable.getElementsByClassName(`${SCROLL_BUTTON_CLASS} Right`).item(0).classList.add('Active');
    if (right === Scroll.ScrollButtonState.DEACTIVATE) scrollable.getElementsByClassName(`${SCROLL_BUTTON_CLASS} Right`).item(0).classList.remove('Active');
}

function createChaptersElements() {

    let chapterContainer = document.getElementById('chapters');
    while (chapterContainer.firstChild) {
        chapterContainer.lastChild.remove();
    }

    let launcher = Player.getLauncher();
    if(launcher.chapters) {
        for (let chapterData of launcher.chapters) {
            let chapterWrapper = document.createElement('div');
            chapterWrapper.classList.add('ChapterWrapper', 'Closed');
            chapterWrapper.time = chapterData.start;

            let chapter = document.createElement('div');
            chapter.classList.add('Chapter');

            let skip = document.createElement('div');
            skip.classList.add('Skip');

            let p = document.createElement('p');
            p.innerText = chapterData.title;

            chapter.appendChild(skip);
            chapter.appendChild(p);
            chapterWrapper.appendChild(chapter);
            chapterContainer.appendChild(chapterWrapper);

            chapter.addEventListener('click', (event) => {
                let chapterWrapper = event.currentTarget.parentElement;
                if (chapterWrapper.classList.contains('Closed')) {
                    chapterWrapper.classList.replace('Closed', 'Open');
                } else {
                    chapterWrapper.classList.replace('Open', 'Closed');
                    Player.setCurrentTime(chapterWrapper.time);
                    Player.play();
                }
            }, {capture : false});

            let pos = chapterData.start / Player.getDuration();
            if(pos > 0.5) chapterWrapper.classList.add('Right');
            chapterWrapper.style.left = `${pos * 100.0}%`;
        }
    }
}

function closeOpenChapters(clicked) {
    if(!clicked.closest('.Chapter') || clicked.closest('.ChapterWrapper.Closed')) {
        for (let toClose of document.getElementsByClassName('Chapter')) {
            if (toClose.parentElement.classList.contains('Open')) toClose.parentElement.classList.replace('Open', 'Closed');
        }
    }
}

async function getChapters(launcher) {
    if(launcher.getAttribute('data-chapters')) {
        launcher.chapters = await fetch(document.baseURI + launcher.getAttribute('data-chapters'))
            .then(response => response.json());
    }
}

function prepareLaunchers() {
    for (let launcher of document.getElementsByClassName('Launch')) {
        prepareLauncher(launcher)
    }
}

function prepareLauncher(launcher) {
    getChapters(launcher);
    launcher.addEventListener('click', function(event) {
        let clicked = event.target;
        let current = Player.getLauncher();
        if(!current || clicked.getAttribute('data-file') !== current.getAttribute('data-file')) {
            let cartridge = launcher.closest('.Cartridge');
            document.getElementById('now-playing').innerHTML = 
                getTitle(cartridge).innerHTML +
                ' - ' +
                getSubtitle(cartridge).innerHTML;
            setFooterPlayingState(true);
            Player.launch(clicked);
        }
    });
}

function updatePlayPause(playingState) {
    if(playingState === Player.PLAYING) {
        document.getElementById('pause').style.display = "block";
        document.getElementById('play').style.display = "none";
    } else {
        document.getElementById('pause').style.display = "none";
        document.getElementById('play').style.display = "block";
    }
}

function updateTimeTotal() {
    document.getElementById('time-total').innerText = Player.getDurationHMS();
}

function updateProgress(currentTimeHMS, currentTime, totalTime) {
    document.getElementById('progress-bar').style.right = `${(1.0 - (currentTime / totalTime)) * 100.0}%`;
    document.getElementById('time-current').innerText = currentTimeHMS;
}

function updateBufferBar(buffered) {
    document.getElementById('buffered-bar').style.right = `${(1.0 - buffered) * 100.0}%`;
}

function getTitle(container) {
    return container.querySelector('.Title');
}

function getSubtitle(container) {
    return container.querySelector('.Subtitle');
}

function updateHere(html) {
    document.getElementById('here').innerHTML = html;
}

function setFooterPlayingState(playing) {
    let footer = document.getElementById('footer');
    if(playing) {
        if(!footer.classList.contains('Playing')) footer.classList.add('Playing');
    } else {
        footer.classList.remove('Playing');
    }
}

let rssButtonHandler = function(event) {
    let rss = event.currentTarget;
    navigator.clipboard.writeText(rss.getAttribute('data-rss')).await;
}

let loginButtonHandler = (event) => {
    let button = event.currentTarget;
    if(button.id === 'login') {
        JsNav.request({ href : event.currentTarget.getAttribute('data-href')});
    } else {
        JsNav.request({
            href : button.getAttribute('data-href'),
            method : button.getAttribute('data-method')
        });
    }
};

function updateLogin(html) {
    let button;
    if((button = document.getElementById('login')) || (button = document.getElementById('logout'))) {
        button.remove();
    }
    if(html) {
        let host = document.createElement('div');
        host.innerHTML = html;
        button = host.firstChild;
        let actions = document.getElementById('actions');
        let rss = document.getElementById('rss');
        if(rss) {
            actions.insertBefore(button, rss);
        } else {
            actions.appendChild(button);
        }
        button.addEventListener('click', loginButtonHandler);
    }
}

function updateRss(html) {
    let button;
    if(button = document.getElementById('rss')) button.remove();
    if(html) {
        let host = document.createElement('div');
        host.innerHTML = html;
        button = host.firstChild;
        document.getElementById('actions').appendChild(button);
        button.addEventListener('click', rssButtonHandler);
    }
}

function loginResponseHandler(body, href, link) {
    let host = document.createElement('div');
    host.innerHTML = body.prompt_html;
    let prompt = host.querySelector('.Login');
    let script = document.createElement('script');
    document.body.appendChild(script);
    script.src = prompt.getAttribute('data-google_login_src');
    script.onload = _event => {
        prompt.getElementsByClassName('Close')[0].addEventListener('click', e => {
            prompt.classList.add('Closed');
            prompt.remove();
        } );
        google.accounts.id.initialize({
            client_id : prompt.getAttribute('data-client_id'),
            callback : handleGoogleToken,
            context : 'signup',
            ux_mode : 'popup'
        });
        prompt.classList.add('Closed');
        document.body.appendChild(prompt);
        google.accounts.id.renderButton(
            document.getElementById('google_button'),
            {
                theme : 'outline',
                size : 'large',
                text : 'signup_with',
                type : 'icon',
                shape : 'circle',
                click_listener : e => {
                    prompt.classList.add('Closed');
                    prompt.remove();
                }
            }
        );
        prompt.classList.remove('Closed');
    }
}

function loginPostResponseHandler(body) {
    window.localStorage.setItem("csrf_token", body.csrf_token);
    let host = document.createElement('div');
    host.innerHTML = body.greeter_html;
    let prompt = host.firstChild;
    document.body.appendChild(prompt);

    prompt.querySelector('button').addEventListener('click', e => {
        prompt.classList.add('Closed');
        prompt.remove();
        window.clearTimeout(timeout);
    })
    let timeout = window.setTimeout(() => {
        if(!prompt.classList.contains('Closed')) prompt.classList.add('Closed');
        prompt.remove();
    }, 2000);
    updateLogin(body.login);
}

function logoutResponseHandler(body) {
    let host = document.createElement('div');
    host.innerHTML = body.goodbye_message;
    let message = host.firstElementChild;
    document.body.appendChild(message);
    let close = message.querySelector('.Close');
    close.addEventListener('click', () => {
       message.classList.add('Closed'); 
       message.remove();
       window.clearTimeout(timeout);
    });
    let timeout = window.setTimeout(() => {
        if(!message.classList.contains('Closed')) message.classList.add('Closed');
        message.remove();
    }, 2000);
    window.localStorage.removeItem('csrf_token');
    updateLogin(body.login);
}

function defaultResponseHandler(body) {
    for(let card of Array.from(MAIN.getElementsByClassName(CARD_CLASS))) {
        card.remove();
    }
    let parent = MAIN[HAS_SCROLL_WRAPPER] ? 
        MAIN.getElementsByClassName(SCROLL_WRAPPER_CLASS).item(0) :
        MAIN;
    parent.innerHTML = body.cards;
    document.title = `ex7.direct - ${body.document_title}`;
    updateHere(body.header_title);
    updateLogin(body.login);
    updateRss(body.rss);
    prepareLaunchers();
    if(MAIN[HAS_SCROLL_WRAPPER]) CARD_SCROLLER.reset();
}

/**
 * @type {import('@petitsys/media-queries-events').MediaQueriesEventsCallback}
 */
function onMediaQueryChange(active) {
    let style = getComputedStyle(document.documentElement);
    if(
        style.getPropertyValue('--landscape') !== "" &&
        !MAIN[HAS_SCROLL_WRAPPER]
    ) {
        if(!CARD_SCROLLER) {
            let [leftButton, rightButton] = createScrollButtons();
            MAIN.appendChild(leftButton);
            MAIN.appendChild(rightButton);
            CARD_SCROLLER = new Scroller(MAIN, {
                scrollWrapperClass : SCROLL_WRAPPER_CLASS,
                scrollToClass : CARD_CLASS,
                leftButton : leftButton,
                rightButton : rightButton,
                onScrollWrapperCreated : (scrollable, wrapper) => moveMainContentToScrollWrapper(scrollable, wrapper),
                onScrollWrapperRemoved : (wrapper, scrollable) => moveScrollWrapperContentToMain(wrapper, scrollable),
                onReachStart : (scrollable) => updateScrollButtons(scrollable, Scroll.ScrollButtonState.DEACTIVATE, Scroll.ScrollButtonState.UNCHANGED),
                onReachEnd : (scrollable) => updateScrollButtons(scrollable, Scroll.ScrollButtonState.UNCHANGED, Scroll.ScrollButtonState.DEACTIVATE),
                onLeaveStart : (scrollable) => updateScrollButtons(scrollable, Scroll.ScrollButtonState.ACTIVATE, Scroll.ScrollButtonState.UNCHANGED),
                onLeaveEnd : (scrollable) => updateScrollButtons(scrollable, Scroll.ScrollButtonState.UNCHANGED, Scroll.ScrollButtonState.ACTIVATE)
            });
        } else {
            CARD_SCROLLER.start();
        }
    } else if(
        style.getPropertyValue('--landscape') === "" &&
        MAIN[HAS_SCROLL_WRAPPER]
    ) {
        CARD_SCROLLER.stop();
    }
}

/**
 * @returns {Element[]}
 */ 
function createScrollButtons() {
    let left = document.createElement('button');
    left.classList.add(SCROLL_BUTTON_CLASS, "Left");
    left.setAttribute("aria-label", "Faire défiler vers la gauche");
    let right = document.createElement('button');
    right.classList.add(SCROLL_BUTTON_CLASS, "Right");
    right.setAttribute("aria-label", "Faire défiler vers la droite");
    return [left, right]
}

window.onload = function() {
    MAIN = document.getElementById('main');

    document.body.addEventListener('click', (event) => {
        closeOpenChapters(event.target);
    }, {capture : true, once : false, passive : true});

    document.title = "ex7.direct - " + document.getElementById('here').innerText.toLowerCase();
    
    let button = document.getElementById('rss');
    if(button) button.addEventListener('click', rssButtonHandler);
    button = document.getElementById('login');
    if(button) button.addEventListener('click', loginButtonHandler);
    button = document.getElementById('logout');
    if(button) button.addEventListener('click', loginButtonHandler);

    JsNav.init({
        csrfTokenName : 'csrf_token',
        responseHandlers : {
            'login' : { 'get' : loginResponseHandler, 'post' : loginPostResponseHandler },
            'logout' : logoutResponseHandler,
            'default' : defaultResponseHandler
        }
    });

    setFooterPlayingState(false);

    let landscape = getComputedStyle(document.documentElement).getPropertyValue('--landscape');
    if(landscape) {
        let [leftButton, rightButton] = createScrollButtons();
        MAIN.appendChild(leftButton);
        MAIN.appendChild(rightButton);
        CARD_SCROLLER = new Scroller(MAIN, {
            scrollWrapperClass : SCROLL_WRAPPER_CLASS,
            scrollToClass : CARD_CLASS,
            leftButton : leftButton,
            rightButton : rightButton,
            onScrollWrapperCreated : (scrollable, wrapper) => moveMainContentToScrollWrapper(scrollable, wrapper),
            onScrollWrapperRemoved : (wrapper, scrollable) => moveScrollWrapperContentToMain(wrapper, scrollable),
            onReachStart : (scrollable) => updateScrollButtons(scrollable, Scroll.ScrollButtonState.DEACTIVATE, Scroll.ScrollButtonState.UNCHANGED),
            onReachEnd : (scrollable) => updateScrollButtons(scrollable, Scroll.ScrollButtonState.UNCHANGED, Scroll.ScrollButtonState.DEACTIVATE),
            onLeaveStart : (scrollable) => updateScrollButtons(scrollable, Scroll.ScrollButtonState.ACTIVATE, Scroll.ScrollButtonState.UNCHANGED),
            onLeaveEnd : (scrollable) => updateScrollButtons(scrollable, Scroll.ScrollButtonState.UNCHANGED, Scroll.ScrollButtonState.ACTIVATE)
        });
    }

    prepareLaunchers();
    Resize.init();
    MediaQueriesEvents.buildList();
    MediaQueriesEvents.addCallback(onMediaQueryChange);

    startServiceWorker()
    .then((worker) => {
        WORKER = worker;
        if(WORKER.state === "activated") {  
            WORKER.postMessage({
                message: Messages.LOAD_HLS
            });
        } else {
            WORKER.addEventListener("statechange", (event) => {
                if(event.target.state === "activated") {
                    WORKER.postMessage({
                        message: Messages.LOAD_HLS
                    });
                }
            });
        }
    })
    .catch((error) => console.log(`oups, une erreur s’est produite : ${error}`));

    navigator.serviceWorker.addEventListener("message", (message) => {
        let data = message.data;
        switch (data.message) {
            case Messages.HLS_LOADED :
                getHls();
                break;
        }
    });
}

function getHls() {
    const script = document.createElement("script");
    script.onload = initPlayer;
    document.head.appendChild(script);
    script.src = "https://cdn.jsdelivr.net/npm/hls.js@latest";
}

function initPlayer() {
    Player.init({
        audio : document.getElementById('audio'),
        onFileInfoLoaded : () => {
            updateTimeTotal();
            createChaptersElements();
        },
        onPlayPause : updatePlayPause,
        onProgress : updateProgress,
        onBuffering : updateBufferBar
    });

    document.getElementById('play').addEventListener('click', function() {
       Player.play();
    });
 
    document.getElementById('pause').addEventListener('click', function() {
        Player.pause();
     });
 
    document.getElementById('scrub-bar').addEventListener('click', function(e) {
        let rect = this.getBoundingClientRect();
        let pos = (e.pageX  - rect.left) / rect.width;
        Player.setCurrentTime(pos * Player.getDuration());
    });
}

window.handleGoogleToken = function (credential_response) {
    const body = new URLSearchParams();
    body.append('credential', credential_response.credential);
    JsNav.request({ href : 'login', method : 'post', body : body });
}

async function startServiceWorker() {
    if ("serviceWorker" in navigator) {
        let registration = await navigator.serviceWorker.register("/worker.js");
        return registration.installing || registration.waiting || registration.active; 
    } else {
        console.error("Service workers are not supported.");
    }
}
